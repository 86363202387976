<template>
  <div>
    <div>
      <div class="pt-5 pb-0">
        <h2 class="text-center text-primary font-size-20 font-weight-bold position-relative main-header mb-5">معرض الفيديوهات</h2>
        <b-container>
          <b-row>
            <b-col md="6" lg="3" v-for="(i, key) in allVideos" :key="key">
              <event-card :item="i" type="video" @click.native="$router.push({name: 'videoDetails', params: {videoId : i.id }})"/>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import eventCard from '@/components/eventCard'
import homeServices from '@/modules/primaryPages/home/services/home'
export default {
  name: 'events',
  components: {
    eventCard
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      allVideos: []
      // allVideos: ['https://ibbil.dafa.dev/videos/1.mp4', 'https://ibbil.dafa.dev/videos/2.mp4', 'https://ibbil.dafa.dev/videos/3.mp4', 'https://ibbil.dafa.dev/videos/4.mp4', 'https://ibbil.dafa.dev/videos/5.mp4', 'https://ibbil.dafa.dev/videos/6.mp4'],
      // vedios: [
      //   {
      //     title: 'الحفل الختامي لمهرجان الملك عبدالعزيز',
      //     content: 'رعى خادم الحرمين الشريفين، الملك سلمان بن عبدالعزيز آل سعود.',
      //     video: 'https://ibbil.dafa.dev/videos/1.mp4'
      //   },
      //   {
      //     title: 'الحفل الختامي لمهرجان الملك عبدالعزيز',
      //     content: 'رعى خادم الحرمين الشريفين، الملك سلمان بن عبدالعزيز آل سعود.',
      //     video: 'https://ibbil.dafa.dev/videos/2.mp4'
      //   },
      //   {
      //     title: 'الحفل الختامي لمهرجان الملك عبدالعزيز',
      //     content: 'رعى خادم الحرمين الشريفين، الملك سلمان بن عبدالعزيز آل سعود.',
      //     video: 'https://ibbil.dafa.dev/videos/3.mp4'
      //   },
      //   {
      //     title: 'الحفل الختامي لمهرجان الملك عبدالعزيز',
      //     content: 'رعى خادم الحرمين الشريفين، الملك سلمان بن عبدالعزيز آل سعود.',
      //     video: 'https://ibbil.dafa.dev/videos/4.mp4'
      //   }
      // ]
    }
  },
  methods: {
    getVideos () {
      homeServices.getAllVideos().then(res => {
        this.allVideos = res.data.data
      })
    }
  },
  created () {
    this.getVideos()
  }
}
</script>
